import * as tslib_1 from "tslib";
import { Artists } from "./artists.service";
import { Player } from "../player/player.service";
import { WpUtils } from "../web-player-utils";
import { MediaItem } from "../media-item.service";
import { map } from "rxjs/operators";
var ArtistService = /** @class */ (function (_super) {
    tslib_1.__extends(ArtistService, _super);
    /**
     * ArtistService Constructor.
     */
    function ArtistService(artists, player) {
        var _this = _super.call(this, player) || this;
        _this.artists = artists;
        _this.player = player;
        /**
         * Top artist tracks.
         */
        _this.topTracks = [];
        return _this;
    }
    /**
     * Get artist top tracks.
     */
    ArtistService.prototype.getTopTracks = function (count) {
        if (count === void 0) { count = 5; }
        return this.topTracks.slice(0, count);
    };
    /**
     * Get similar artists.
     */
    ArtistService.prototype.getSimilar = function (count) {
        if (count === void 0) { count = 20; }
        if (!this.item.similar)
            return [];
        return this.item.similar.slice(0, count);
    };
    /**
     * Check if artist has any similar artists.
     */
    ArtistService.prototype.hasSimilar = function () {
        return this.item.similar.length;
    };
    /**
     * Get loaded albums of artist.
     */
    ArtistService.prototype.getAlbums = function () {
        return this.paginatedData;
    };
    /**
     * Get artist biography and images.
     */
    ArtistService.prototype.getBio = function () {
        return this.item.bio;
    };
    /**
     * Destroy artist service.
     */
    ArtistService.prototype.destroy = function () {
        this.topTracks = [];
        _super.prototype.destroy.call(this);
    };
    /**
     * Load next infinite load albums page.
     */
    ArtistService.prototype.loadNextPage = function () {
        var _this = this;
        return this.artists.paginateArtistAlbums(this.item.id, this.pagination.current_page + 1).pipe(map(function (response) {
            response.data = _this.addArtistToAlbums(response.data);
            return response;
        }));
    };
    /**
     * Get tracks from specified albums.
     */
    ArtistService.prototype.getTracks = function (paginatedData) {
        var data = (paginatedData ? paginatedData : this.paginatedData), tracks = [];
        data.forEach(function (album) {
            if (album && album.tracks) {
                tracks = tracks.concat(WpUtils.assignAlbumToTracks(album.tracks, album));
            }
        });
        // if paginated tracks were not specified, it means initial
        // data is being requested, so we should prepend top tracks to it
        if (!paginatedData) {
            tracks = this.topTracks.concat(tracks);
        }
        return tracks;
    };
    /**
     * Load artist from backend.
     */
    ArtistService.prototype.loadItem = function (id, params) {
        if (params === void 0) { params = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.artists.get(id, params).toPromise()];
                    case 1:
                        response = _a.sent();
                        response.albums.data = this.addArtistToAlbums(response.albums.data, response.artist);
                        this.setItem(response.artist, response.albums);
                        this.topTracks = response.top_tracks || [];
                        return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * Add simplified artist object to all albums, for generating urls and context menus.
     */
    ArtistService.prototype.addArtistToAlbums = function (albums, artist) {
        if (!artist)
            artist = this.item;
        if (!artist)
            return;
        return albums.map(function (album) {
            album.artist = { name: artist.name, id: artist.id };
            return album;
        });
    };
    return ArtistService;
}(MediaItem));
export { ArtistService };
